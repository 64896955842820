import { default as firstrunK7YAonA0nfMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/firstrun.vue?macro=true";
import { default as forgot_45passwordOzrKFFhFEqMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/forgot-password.vue?macro=true";
import { default as indexDvW8KN3xuWMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/index.vue?macro=true";
import { default as loginvQswdyBnzGMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/login.vue?macro=true";
import { default as meet_45the_45teamKSS1ud2pbMMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/meet-the-team.vue?macro=true";
import { default as _91_91filter_93_9375bXm6aWkTMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/move-notifications/index/[[filter]].vue?macro=true";
import { default as addG2XVNISyZOMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/movers/add.vue?macro=true";
import { default as _91id_93RhmX0zPLWFMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/movers/edit/[id].vue?macro=true";
import { default as index3FdQm7GcN5Meta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/movers/index.vue?macro=true";
import { default as _91_91step_93_93kAWjJEzpNcMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/add/[[step]].vue?macro=true";
import { default as _91_91step_93_932VcUn41ZUZMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/edit/[id]/[[step]].vue?macro=true";
import { default as indexAcF1NZ8JyKMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/index.vue?macro=true";
import { default as pendingOX1jh97Mo4Meta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/pending.vue?macro=true";
import { default as _91id_93UNvpn7pqXjMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/view/[id].vue?macro=true";
import { default as _91_91step_93_93b7jQ0MS6WSMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/void/[id]/[[step]].vue?macro=true";
import { default as indexMRPCeMlxhDMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/void/index.vue?macro=true";
import { default as addEpeAhA23OSMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/partner-users/add.vue?macro=true";
import { default as _91id_932hFrx1PyL4Meta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/partner-users/edit/[id].vue?macro=true";
import { default as indexTQDiiomrY0Meta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/partner-users/index.vue?macro=true";
import { default as _91token_93II55iKRImgMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/password/reset/[token].vue?macro=true";
import { default as addFNudxQCELdMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/properties/add.vue?macro=true";
import { default as _91id_93j3iu65FWtGMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/properties/edit/[id].vue?macro=true";
import { default as indexPVlFaRaW0oMeta } from "/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/properties/index.vue?macro=true";
export default [
  {
    name: "firstrun",
    path: "/firstrun",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/firstrun.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordOzrKFFhFEqMeta || {},
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginvQswdyBnzGMeta || {},
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "meet-the-team",
    path: "/meet-the-team",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/meet-the-team.vue").then(m => m.default || m)
  },
  {
    name: "move-notifications-index-filter",
    path: "/move-notifications/:filter?",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/move-notifications/index/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: "movers-add",
    path: "/movers/add",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/movers/add.vue").then(m => m.default || m)
  },
  {
    name: "movers-edit-id",
    path: "/movers/edit/:id()",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/movers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "movers",
    path: "/movers",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/movers/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-add-step",
    path: "/moves/add/:step?",
    meta: _91_91step_93_93kAWjJEzpNcMeta || {},
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/add/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-edit-id-step",
    path: "/moves/edit/:id()/:step?",
    meta: _91_91step_93_932VcUn41ZUZMeta || {},
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/edit/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves",
    path: "/moves",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-pending",
    path: "/moves/pending",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/pending.vue").then(m => m.default || m)
  },
  {
    name: "moves-view-id",
    path: "/moves/view/:id()",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "moves-void-id-step",
    path: "/moves/void/:id()/:step?",
    meta: _91_91step_93_93b7jQ0MS6WSMeta || {},
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/void/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-void",
    path: "/moves/void",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/moves/void/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-add",
    path: "/partner-users/add",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/partner-users/add.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-edit-id",
    path: "/partner-users/edit/:id()",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/partner-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "partner-users",
    path: "/partner-users",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/partner-users/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93II55iKRImgMeta || {},
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "properties-add",
    path: "/properties/add",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/properties/add.vue").then(m => m.default || m)
  },
  {
    name: "properties-edit-id",
    path: "/properties/edit/:id()",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/properties/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "properties",
    path: "/properties",
    component: () => import("/home/ubuntu/actions-runner/_work/jmi/jmi/apps/partner/pages/properties/index.vue").then(m => m.default || m)
  }
]